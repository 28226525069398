.main {
  width: 100%;
  margin: 12rem auto 8rem auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.cell {
  cursor: pointer;
  overflow: hidden;
  margin: 3.6rem;
  flex: 1 1 40rem;
}

.cell > .cellTop {
  overflow: hidden;
  position: relative;
  border-radius: 2rem;
  /* padding: 1rem; */
  border-radius: 3rem;
  /* background-color: var(--Bg_Color_Three); */
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

}
.cell > .cellTop::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .3s;
}
.cell > .cellTop:hover::before {
 
  background-color: #1f223559;
  transition: .3s;
}
.pImg {
  width: 100%;
  height: auto;
  border-radius: 3rem;

}


.overlay {
  position: fixed;
  top: 10vh;
  left: 20vw;
  bottom: 10vh;
  z-index: 1003;
  right: 20vw;
}

.overlayTop {
  position: relative;
  overflow: hidden;
  border-radius: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlayTop > .oImg {
  width: 100%;
  border-radius: 3rem;
}

.closeIcon {
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  color: white;
  opacity: 0.3;
  cursor: pointer;
}

.closeIcon:hover {
  opacity: 1;
}



.overlayBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000044;
  z-index: 1002;

}

@media screen and (max-width: 1024px) {
  .pImg {
    border-radius: 2rem;
  }
  .overlay {
    left: 0vw;
    right: 0vw;
  }
}

@media screen and (max-width: 674px) {
  .main {
    margin: 8rem 0rem;
  }
  .cell {
    margin: 2rem 0rem;
  }
  .cell > .cellTop{
    padding: 0rem 2rem;
  }
  .overlay {
    padding: 1rem;
  }
}
